import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import BlogListing from '../components/BlogListing';

const DonationThanksPage = () => {
  const {
    site: {
      globalSeo: { titleSuffix },
    },
    datoCmsPay: { bannerImage },
  } = useStaticQuery(graphql`
    query DonationThanksPageQuery {
      site: datoCmsSite {
        globalSeo {
          titleSuffix
        }
      }
      datoCmsPay {
        bannerImage {
          ...SubPageBannerImageFragment
        }
      }
    }
  `);

  const title = 'Thanks for your donation';

  return (
    <Layout>
      <HelmetDatoCms title={title} titleTemplate={`%s${titleSuffix}`} />
      <main>
        <Banner
          heading={title}
          image={bannerImage}
          text="See what we've been talking about lately and how your donation helps by checking out our latest blog posts below"
          isThanksPage={true}
        />
        <BlogListing heading="What's going on at Barnstondale?" />
      </main>
    </Layout>
  );
};

export default DonationThanksPage;
